/**
 * Doc Type Button Component
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */

//libary imports
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Typography from "@mui/material/Typography";

//styles
import {
  PPDLButton,
  DocTypeButtonIconItem as LeftItem,
} from "./DocTypeButton.styles";
import styles from "./DocTypeButton.module.css";
//files
import DLIconImg from "../../images/dl_icon.png";
import PPIconImg from "../../images/pp_icon.png";
//context
import { DocVerificationContext } from "../../context/DocVerificationContext";

const DocTypeButton = ({ docType, nextPageLink }) => {
  const { updateSelectedDocType } = useContext(DocVerificationContext);
  const navigate = useNavigate();

  const [docTypeImg, setDocTypeImg] = useState(
    <img className={styles.ppDLLogo} src={DLIconImg} alt="logo" />
  );
  const [docTypeButtonText, setDocTypeButtonText] = useState(
    "Driver's license or ID Card"
  );

  const clicked = () => {
    let docTypeClicked = "driversLicense";
    switch (docType) {
      case "passport":
        docTypeClicked = "passport";
        break;
      default:
    }
    updateSelectedDocType(docTypeClicked);
    navigate(`${nextPageLink}`, { replace: true });
  };

  useEffect(() => {
    switch (docType) {
      case "passport":
        setDocTypeImg(
          <img className={styles.ppDLLogo} src={PPIconImg} alt="logo" />
        );
        setDocTypeButtonText("Passport");
        break;
      default:
      // console.log("setting image and text to default");
    }
  }, [docType]);

  return (
    <PPDLButton onClick={clicked}>
      <Stack direction="row" spacing={2}>
        <LeftItem> {docTypeImg}</LeftItem>
        <Typography
          sx={{
            color: "black",
            fontWeight: 400,
            paddingTop: "0.25rem",
            fontSize: "1.2rem",
            fontFamily: "Roboto",
            textTransform: "capitalize",
            width: "10rem",
          }}
        >
          {docTypeButtonText}
        </Typography>
        <ArrowForwardIosIcon
          sx={{
            color: "black",
            paddingTop: "0.65rem",
            fontSize: "2rem",
            paddingLeft: "2.5rem",
          }}
        />
      </Stack>
    </PPDLButton>
  );
};
export default DocTypeButton;
