/**
 * Doc Type Page
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */

//libary imports
import React, { useContext, useEffect } from "react";
import { Grid, Stack, Typography } from "@mui/material";

//components
import TuHeader from "../../components/TuHeader/TuHeader";

//styles
import { DocTypeStyles, DocTypeItemBlock, DocTypePageItem as Item } from "./DocType.styles";

//component imports
import DocTypeButton from "../../components/DocTypeButton/DocTypeButton";
import ProgressBar from "../../components/ProgressBar/ProgressBar";

const DocType = () => {
  return (
    <Grid container spacing={2} sx={DocTypeStyles.pageWrapper}>
      <TuHeader />
      <Grid item xs={12} sx={DocTypeStyles.sectionWrapper}>
        <ProgressBar progressPhase={1} />
      </Grid>

      <Grid item xs={12} sx={DocTypeStyles.sectionWrapper}>
        <Typography
          sx={{
            fontSize: "1.5rem",
            fontFamily: "Roboto",
            fontWeight: 700,
            color: "black",
            marginTop: "2rem",
          }}
        >
          Choose the type of document you wish to submit
        </Typography>
      </Grid>
      <Grid sx={DocTypeStyles.sectionWrapper}>
        <Stack direction="column" spacing={2}>
          <DocTypeButton
            docType={"drivers license"}
            nextPageLink={`../idUpload/`}
          />
          <DocTypeButton docType="passport" nextPageLink={`../idUpload/`} />
        </Stack>
      </Grid>
    </Grid>
  );
};
export default DocType;
