/**
 * Front Back Selfie Button Styling
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";

export const FBSButton = styled(Button)(({ theme }) => ({
  color: "black",
  // height: "2.5rem",
  width: "20rem",
  height: "5rem",
  backgroundColor: "white",
  boxShadow: "0 1px 3px 1px rgb(0 0 0 / 25%)",
  fontFamily: ["Roboto", "sans-serif"].join(","),
  textAlign: "left",
}));
