/**
 * Doc Type Page
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */

//libary imports
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Grid,
  Stack,
  Typography,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
//components
import TuHeader from "../../components/TuHeader/TuHeader";
//styles
import {
  DocPicPageItem as Item,
  DocPicNextButton,
  DocPicPageStyles,
} from "./DocPicturePage.styles";
import styles from "./DocPicturePage.module.css";
//component imports
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import FrontBackSelfieButton from "../../components/FrontBackSelfieButton/FrontBackSelfieButton";
//files
import PicGif from "../../images/take_the_picture.gif";
//context
import { DocVerificationContext } from "../../context/DocVerificationContext";

const DocPicturePage = () => {
  const {
    updateFrontBackUploaded,
    frontBackUploaded,
    frontUploaded,
    backUploaded,
    backImageUrl,
    frontImageUrl,
    shortcode,
    updateFrontImageUrl,
    updateBackImageUrl,
    updateFrontUploaded,
    updateBackUploaded,
    updateDocumentSubmitted,
    selfieRequired,
  } = useContext(DocVerificationContext);
  const navigate = useNavigate();

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const handleDialog = () => {
    setOpenDialog(!openDialog);
  };
  useEffect(() => {
    if (frontUploaded && backUploaded) {
      updateFrontBackUploaded();
    }
  }, [frontUploaded, backUploaded]);

  const nextPageClicked = () => {
    if (frontBackUploaded) {
      const type = window.tudv.DocumentType.LICENSE;
      setOpenBackdrop(true);

      window.tudv.postDocumentToServer(
        shortcode,
        type,
        frontImageUrl,
        backImageUrl,
        (error, response) => {
          if (error) {
            console.error("Error submitting document to server", error);
            alert(error);
            setOpenBackdrop(false);
          } else {
            setOpenBackdrop(false);
            let rescanNeeded = false;
            if (response.front && response.front == "rescan") {
              console.log("Rescan needed for front.", response);
              updateFrontImageUrl("");
              updateFrontUploaded();
              rescanNeeded = true;
            }
            if (response.back && response.back == "rescan") {
              console.log("Rescan needed for back.", response);
              updateBackImageUrl("");
              updateBackUploaded();
              rescanNeeded = true;
            }
            if (!rescanNeeded) {
              updateDocumentSubmitted();
              if (selfieRequired)
                navigate(`../selfieUpload`, { replace: true });
              else navigate(`../thanks`, { replace: true });
            } else {
              updateFrontBackUploaded();
              setOpenDialog(true);
            }
          }
        }
      );
    }
  };

  return (
    <Grid container spacing={2} sx={DocPicPageStyles.pageWrapper}>
      <Backdrop open={openBackdrop} sx={{ color: "#fff", zIndex: "5999" }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <TuHeader />
      <Grid item xs={12} sx={DocPicPageStyles.sectionWrapper}>
        <ProgressBar progressPhase={2} />
      </Grid>
      <Grid item xs={12} sx={DocPicPageStyles.sectionWrapper}>
        <img
          className={styles.takePic}
          src={PicGif}
          alt="gif"
          style={DocPicPageStyles.sectionWrapper}
        />
      </Grid>
      <Grid item xs={12} sx={DocPicPageStyles.sectionWrapper}>
        <FrontBackSelfieButton
          key="frontId"
          imageType="front"
          imageCaptureLink={`../capturePhoto`}
        />
      </Grid>
      <Grid item xs={12} sx={DocPicPageStyles.sectionWrapper}>
        <FrontBackSelfieButton
          key="backId"
          imageType="back"
          imageCaptureLink={`../capturePhoto`}
        />
      </Grid>
      <Grid item xs={12} sx={DocPicPageStyles.sectionWrapper}>
        <Stack direction="column" spacing={2}>
          {frontBackUploaded ? (
            <DocPicNextButton onClick={nextPageClicked}>
              <Stack direction="row">
                <Typography
                  sx={{
                    textTransform: "capitalize",
                    fontSize: "1rem",
                    paddingTop: "0.25rem",
                  }}
                >
                  Submit Documents
                </Typography>
                <ArrowForwardIosIcon
                  sx={{
                    fontSize: "2rem",
                    color: "white",
                    paddingLeft: "0.5rem",
                  }}
                />
              </Stack>
            </DocPicNextButton>
          ) : (
            <></>
          )}
        </Stack>
      </Grid>
      <Dialog open={openDialog} onClose={handleDialog}>
        <DialogTitle>Re-take Needed</DialogTitle>
        <DialogContent>
          <Typography>
            Looks like we're not able to find your document details in the
            pictures taken. Please re-take a picture for:
          </Typography>
          <hr />
          <ul>
            {!frontUploaded && (
              <li>
                <Typography>Front of card</Typography>
              </li>
            )}
            {!backUploaded && (
              <li>
                <Typography>Back of card</Typography>
              </li>
            )}
          </ul>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialog}>OK</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
export default DocPicturePage;
