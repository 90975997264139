/**
 * Tu logo header component
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */

//libary imports
import React from "react";
import Grid from "@mui/material/Grid";
//files
import tuLogo from "../../images/tulogo.png";
//styles
import styles from "./TuHeader.module.css";
import { TuHeaderItem as Item } from "./TuHeader.styles";

const TuHeader = (props) => {
  return (
        <div style={{display:"flex", justifyContent:'center', width:"100%"}}>
          <img className={styles.tuLogo} src={tuLogo} alt="logo" />
        </div>
  );
};
export default TuHeader; 
