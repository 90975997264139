/**
 * Tu logo header component
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */

//libary imports
import React from "react";
import Grid from "@mui/material/Grid";
//files
import progress1 from "../../images/progress_1.png";
import progress2 from "../../images/progress_2.png";
import progress3 from "../../images/progress_3.png";
//styles
import styles from "./ProgressBar.module.css";
import { ProgressBarItem as Item } from "./ProgressBar.styles";

const ProgressBar = ({ progressPhase }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Item>
          <img
            className={styles.progress}
            src={
              progressPhase === 1
                ? progress1
                : progressPhase === 2
                ? progress2
                : progress3
            }
            alt="logo"
          />
        </Item>
      </Grid>
    </Grid>
  );
};
export default ProgressBar;
