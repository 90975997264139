/**
 * Demo Flow Context
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */

import { createContext } from "react";

/**
 * this context is in charge of updating the aws user signed in
 * initially set to not logged in, and the username is empty
 */
export const DocVerificationContext = createContext({
  selectedDocType: "",
  updateSelectedDocType: () => {},
  frontUploaded: false,
  updateFrontUploaded: () => {},
  backUploaded: false,
  updateBackUploaded: () => {},
  frontBackUploaded: false,
  updateFrontBackUploaded: () => {},
  //front, back, selfie
  imageToCapture: "",
  updateImageToCapture: () => {},
  frontImageUrl: "",
  updateFrontImageUrl: () => {},
  backImageUrl: "",
  updateBackImageUrl: () => {},
  selfieImageUrl: "",
  updateSelfieImageUrl: () => {},
  selfieUploaded: false,
  updateSelfieUploaded: () => {},
  shortcode: "",
  updateShortcode: () => {},
  showConsentLoading: false,
  updateShowConsentLoading: () => {},
  updateDocumentSubmitted: () => {},
  updateSelfieSubmitted: () => {},
  updateVerificationCompleted: () => {},
  documentSubmitted: false,
  selfieSubmitted: false,
  verificationCompleted: false,
  selfieRequired: false,
  setSelfieRequired: () => {},
});
