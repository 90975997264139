/**
 * Consent Page Styling
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material";

export const ConsentPageButtonItem = styled(Paper)(({ theme }) => ({
  backgroundColor: "white",
  padding: theme.spacing(1),
  textAlign: "center",
  color: "black",
  boxShadow: "none",
}));
export const ConsentPageItem = styled(Grid)(({ theme }) => ({
  backgroundColor: "white",
  textAlign: "center",
  color: "black",
  ["@media (max-width: 575.98px)"]: {
    // eslint-disable-line no-useless-computed-key

  },
}));

export const ConsentPageItemConsentNotice = styled(Grid)(({ theme }) => ({
  backgroundColor: "white",
  paddingTop: "10px",
  fontSize: "1.5rem",
  color: "#00b4d8",
  fontFamily: "roboto",
  fontWeight: "bold",
  boxShadow: "none",
  ["@media (max-width: 575.98px)"]: {
    // eslint-disable-line no-useless-computed-key
    paddingLeft: "10px",
    paddingRight: "10px",
  },
}));
export const ConsentPageItemContent = styled(Grid)(({ theme }) => ({
  backgroundColor: "transparent",
  padding: theme.spacing(2),
  color: "black",
  boxShadow: "none",
  paddingLeft: "1rem",
  paddingRight: "1rem",
  fontSize: "1rem",
  fontFamily: "roboto",
  fontWeight: 300,
  ["@media (min-width: 1200px)"]: {
    // eslint-disable-line no-useless-computed-key
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    fontSize: "1.5rem"
  },
}));
export const consentPageStyles = {
  mainBox: { 
    flexGrow: 1, background: "white", padding: "10px",
    ["@media (min-width: 1200px)"]: {
      // eslint-disable-line no-useless-computed-key
      paddingLeft: "80px",
      paddingRight: "80px",
    }
  },
};

export const ConsentButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "#00b4d8",
  "&:hover": {
    backgroundColor: "#00b4d8",
  },
  fontFamily: ["Oswald", "sans-serif"].join(","),
  fontSize: "1.25rem",
}));

export const BackButton = styled(Button)(({ theme }) => ({
  color: "white",
  height: "2.5rem",
  width: "7rem",
  height: "3rem",
  backgroundColor: "#00b4d8",
  "&:hover": {
    backgroundColor: "#00b4d8",
  },
  fontFamily: ["Oswald", "sans-serif"].join(","),
}));
