/**
 * Progress Bar component styles
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

export const ProgressBarItem = styled(Paper)(({ theme }) => ({
  backgroundColor: "white",
  textAlign: "center",
  boxShadow: "none",
}));
