/**
 * Thankyou Page Styling
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

export const ThankYouPageStyles = {
  pageWrapper: {width:"100%", margin:"0px", padding:"10px", justifyContent:"center", textAlign:"center"},
  sectionWrapper: {display:"flex", justifyContent:"center",marginTop:"20px"},
}


export const ThankyouPageItem = styled(Paper)(({ theme }) => ({
  backgroundColor: "white",
  padding: theme.spacing(1),
  textAlign: "center",
  color: "black",
  ["@media (max-width: 575.98px)"]: {
    // eslint-disable-line no-useless-computed-key
    width: "23rem",
  },
  boxShadow: "none",
  marginTop: "2rem",
}));
