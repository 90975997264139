/**
 * Consent Page
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */

//libary imports
import React, { useEffect, useContext } from "react";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

//components
import TuHeader from "../../components/TuHeader/TuHeader";

//styles
import {
  ThankyouPageItem as Item,
  ThankYouPageStyles,
} from "./ThankyouPage.styles";

//services
import { DocVerificationContext } from "../../context/DocVerificationContext";

const ThankyouPage = () => {
  const {
    shortcode,
    documentSubmitted,
    selfieSubmitted,
    verificationCompleted,
    updateVerificationCompleted,
    selfieRequired,
  } = useContext(DocVerificationContext);
  const thanksTextLine1 = `Your documents have been submitted for verification.`;
  const thanksTextLine2 = `Please return to the original webpage to complete the signup process.  Additional information is needed before your account can be activated.`;
  useEffect(() => {
    if (
      !verificationCompleted &&
      documentSubmitted &&
      ((selfieRequired && selfieSubmitted) || !selfieRequired)
    ) {
      const type = window.tudv.DocumentType.LICENSE;

      window.tudv.completeVerification(type, shortcode, (error) => {
        if (error) {
          console.error(
            "Error submitting document verification completion signal to server",
            error
          );
          alert(error);
        } else {
          updateVerificationCompleted();
        }
      });
    }
  }, [
    verificationCompleted,
    documentSubmitted,
    selfieSubmitted,
    updateVerificationCompleted,
  ]);

  return (
    <Grid container spacing={2} sx={ThankYouPageStyles.pageWrapper}>
      <TuHeader />
      <Grid item xs={12} sx={ThankYouPageStyles.sectionWrapper}>
        <Stack direction="column">
          <Typography
            sx={{
              fontSize: "2rem",
              color: "#00A7CE",
              fontFamily: "Roboto",
              fontWeight: 700,
              marginBottom: "30px",
            }}
          >
            Thank You!
          </Typography>
          <Typography
            sx={{
              fontSize: "1rem",
              color: "black",
              fontFamily: "Roboto",
              fontWeight: 400,
              marginBottom: "30px",
            }}
          >
            {thanksTextLine1}
          </Typography>
          <Typography
            sx={{
              fontSize: "1rem",
              color: "black",
              fontFamily: "Roboto",
              fontWeight: 400,
              marginLeft: "2rem",
              marginRight: "2rem",
            }}
          >
            {thanksTextLine2}
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};
export default ThankyouPage;
