/**
 * Doc Type Page Styling
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

export const DocTypeStyles = {
  pageWrapper: {width:"100%", margin:"0px", padding:"10px", justifyContent:"center", textAlign:"center"},
  sectionWrapper: {marginTop:"20px", padding:"0 10px"},
};

export const DocTypePageItem = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  textAlign: "center",
  height: "2.5rem",
  color: "#013a63",
  fontSize: "1.75rem",
  fontFamily: "Oswald",
  marginLeft: "1.5rem",
  marginRight: "1.5rem",
  height: "100vh",
  marginTop: "2rem",
  boxShadow: "none",
}));

export const DocTypeItemBlock = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  marginLeft: "2rem",
  marginRight: "2rem",
  display: "block",
  boxShadow: "none",
}));
