/**
 * Consent Page
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */

//libary imports
import React, { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
//components
import TuHeader from "../../components/TuHeader/TuHeader";
import ConsentLoading from "../../components/ConsentLoading/ConsentLoading";
//styles
import {
  ConsentPageItemConsentNotice,
  consentPageStyles,
  ConsentPageItemContent,
  ConsentButton,
  ConsentPageButtonItem,
} from "./ConsentPage.styles";

//context
import { DocVerificationContext } from "../../context/DocVerificationContext";

const ConsentPage = () => {
  const { shortCode } = useParams();
  const { updateShortcode, updateShowConsentLoading } = useContext(
    DocVerificationContext
  );
  const consentTextLine1 = `By using this app, you consent to allow us to collect and use your biometric and personal information (collectively, "Information") obtained from any ID, selfie or related personal document, solely to be used for our verification purposes. We will retain the information only until the initial purpose for collecting or obtaining such information has been satisfied or for three years after your last interaction with us, whichever occurs first`;
  const consentTextLine2 = `This service is intended for U.S. residents and documentation only. If you upload non-U.S. identification documentation, you understand that you are causing your personal information contained in the documentation to be transferred out of your country of origin and into the United States, and we cannot guarantee that the data will be maintained in accordance with the data protection laws of your home country.`;
  const consentTextLine3 = `Your information is confidential and will only be used and shared according to our privacy notice.`;
  const consentTextLine4 = `Terms and Conditions`;
  const consentTextLine5 = `Transunion offers the ability for applicants to supply their identification digitally in connection with your application (the "Service"). The Service text messaging is free to end user (FTEU). You must be the mobile phone account holder and the applicant to use this Service. Transunion and your wireless provider are not liable for any delayed or undelivered messages. Messages may be delayed or not delivered due to factors outside of the wireless provider's control and Transunion's control. Please review our Privacy Pledge at https://www.transunion.com/privacy/transunion`;
  const navigate = useNavigate();

  const consentButtonClicked = async () => {
    updateShowConsentLoading(true);
    
    if(shortCode) {
      window.tudv.postConsent(shortCode, (error) => {
        if(error) {
          console.error("Error posting consent to TruValidate servers", error);
        }
        else
          navigate("../DocType", { replace: true });
      });
    }      

    updateShowConsentLoading(false);
  };

  useEffect(() => {
    updateShortcode(shortCode);
  }, [shortCode, updateShortcode]);

  return (
    <Box sx={consentPageStyles.mainBox}>
      <ConsentLoading />
      <Grid container spacing={2}> 
        <TuHeader sx={{paddingTop: "10px"}}/>
        <ConsentPageItemConsentNotice item xs={12}>
            Consent Notice
        </ConsentPageItemConsentNotice>
        <ConsentPageItemContent item xs={12}>{consentTextLine1}</ConsentPageItemContent>
        <ConsentPageItemContent item xs={12}>{consentTextLine2}</ConsentPageItemContent>
        <ConsentPageItemContent item xs={12}>{consentTextLine3}</ConsentPageItemContent>
        <ConsentPageItemContent item xs={12}>{consentTextLine4}</ConsentPageItemContent>
        <ConsentPageItemContent item xs={12}>{consentTextLine5}</ConsentPageItemContent>
        <Grid item xs={12}>
          <ConsentPageButtonItem>
            <ConsentButton variant="contained" onClick={consentButtonClicked}>
              I agree
            </ConsentButton>
          </ConsentPageButtonItem>
        </Grid>
      </Grid>
    </Box>
  );
};
export default ConsentPage;
