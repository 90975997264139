/**
 * Main Application Page
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */

//library imports
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Amplify, API } from "aws-amplify";
import { AWSIoTProvider } from "@aws-amplify/pubsub";
//page
import ConsentPage from "./pages/ConsentPage/ConsentPage";
import DocType from "./pages/DocType/DocType";
import DocPicturePage from "./pages/DocPicturePage/DocPicturePage";
import SelfiePicturePage from "./pages/SelfiePicturePage/SelfiePicturePage";
import ThankyouPage from "./pages/ThankyouPage/ThankyouPage";

import "./App.css";
//context
import { DocVerificationContext } from "./context/DocVerificationContext";
import IdCardSelfiePicture from "./pages/IdCardSelfiePicture/IdCardSelfiePicture";
//config
import awsConfig from "./aws-exports";

function App() {
  Amplify.configure(awsConfig);
  API.configure(awsConfig);
  Amplify.addPluggable(
    new AWSIoTProvider({
      aws_pubsub_region: "us-east-1",
      aws_pubsub_endpoint:
        "wss://a3ermkjx5bwouq-ats.iot.us-east-1.amazonaws.com/mqtt",
    })
  );

  const [selectedDocType, setSelectedDocType] = useState("");
  const [frontUploaded, setFrontUploaded] = useState(false);
  const [backUploaded, setBackUploaded] = useState(false);
  const [frontBackUploaded, setFrontBackUploaded] = useState(false);
  const [selfieUploaded, setSelfieUploaded] = useState(false);
  const [imageToCapture, setImageToCapture] = useState("");
  const [frontImageUrl, setFrontImageUrl] = useState("");
  const [backImageUrl, setBackImageUrl] = useState("");
  const [selfieImageUrl, setSelfieImageUrl] = useState("");
  const [shortcode, setShortcode] = useState("");
  const [showConsentLoading, setShowConsentLoading] = useState(false);
  const [documentSubmitted, setDocumentSubmitted] = useState(false);
  const [selfieSubmitted, setSelfieSubmitted] = useState(false);
  const [selfieRequired, setSelfieRequired] = useState(false);
  const [verificationCompleted, setVerificationCompleted] = useState(false);

  const updateShowConsentLoading = (boolVal) => {
    setShowConsentLoading(boolVal);
  };
  const updateSelfieUploaded = useCallback(() => {
    setSelfieUploaded(!selfieUploaded);
  }, [selfieUploaded]);
  const updateSelfieImageUrl = useCallback((url) => {
    setSelfieImageUrl(url);
  }, []);
  const updateFrontImageUrl = useCallback((url) => {
    setFrontImageUrl(url);
  }, []);
  const updateBackImageUrl = useCallback((url) => {
    setBackImageUrl(url);
  }, []);
  const updateImageToCapture = useCallback((newSetting) => {
    setImageToCapture(newSetting);
  }, []);
  const updateFrontBackUploaded = useCallback(() => {
    setFrontBackUploaded(!frontBackUploaded);
  }, [frontBackUploaded]);

  const updateSelectedDocType = useCallback((selectedType) => {
    setSelectedDocType(selectedType);
  }, []);
  const updateFrontUploaded = useCallback(() => {
    setFrontUploaded(!frontUploaded);
  }, [frontUploaded]);
  const updateBackUploaded = useCallback(() => {
    setBackUploaded(!backUploaded);
  }, [backUploaded]);
  const updateShortcode = useCallback(
    (newcode) => {
      setShortcode(newcode);
    },
    [shortcode]
  );
  const updateDocumentSubmitted = useCallback(() => {
    setDocumentSubmitted(!documentSubmitted);
  }, [documentSubmitted]);
  const updateSelfieSubmitted = useCallback(() => {
    setSelfieSubmitted(!selfieSubmitted);
  }, [selfieSubmitted]);
  const updateVerificationCompleted = useCallback(() => {
    setVerificationCompleted(!verificationCompleted);
  }, [verificationCompleted]);

  const docVerContext = useMemo(() => {
    return {
      selectedDocType,
      updateSelectedDocType,
      frontUploaded,
      updateFrontUploaded,
      backUploaded,
      updateBackUploaded,
      frontBackUploaded,
      updateFrontBackUploaded,
      imageToCapture,
      updateImageToCapture,
      frontImageUrl,
      updateFrontImageUrl,
      backImageUrl,
      updateBackImageUrl,
      selfieImageUrl,
      updateSelfieImageUrl,
      selfieUploaded,
      updateSelfieUploaded,
      shortcode,
      updateShortcode,
      showConsentLoading,
      updateShowConsentLoading,
      updateDocumentSubmitted,
      updateSelfieSubmitted,
      updateVerificationCompleted,
      documentSubmitted,
      selfieSubmitted,
      verificationCompleted,
      selfieRequired,
      setSelfieRequired,
    };
  }, [
    selectedDocType,
    updateSelectedDocType,
    frontUploaded,
    updateFrontUploaded,
    backUploaded,
    updateBackUploaded,
    frontBackUploaded,
    updateFrontBackUploaded,
    imageToCapture,
    updateImageToCapture,
    frontImageUrl,
    updateFrontImageUrl,
    backImageUrl,
    updateBackImageUrl,
    selfieImageUrl,
    updateSelfieImageUrl,
    selfieUploaded,
    updateSelfieUploaded,
    shortcode,
    updateShortcode,
    showConsentLoading,
    updateShowConsentLoading,
    updateDocumentSubmitted,
    updateSelfieSubmitted,
    updateVerificationCompleted,
    documentSubmitted,
    selfieSubmitted,
    verificationCompleted,
    selfieRequired,
    setSelfieRequired,
  ]);

  /*
    Setup the TruValidate JS SDK for document authentication API calls
  */
  useEffect(() => {
    //required by authentic id
    window.tudvLoadConfig = (CONFIG_NUM_TYPE, load) => {
      let configFileName;
      switch (CONFIG_NUM_TYPE) {
        case 1:
          configFileName = "../config.general.json";
          break;
        case 2:
          configFileName = "../config.vendor.json";
          break;
        default:
          return void load("unknown configType");
      }
      fetch(configFileName)
        .then((e) => {
          if (!e.ok) throw new Error("Error downloading config file");
          return e.json();
        })
        .then((e) => {
          load(null, e);
        })
        .catch((e) => {
          load(e);
        });
    };

    window.tudv.initialize((initError) => {
      if (initError) {
        console.error(initError);
        return;
      }
      console.log("SDK Initialized");
    });
  }, []);

  return (
    <div className="main-app-body">
      <DocVerificationContext.Provider value={docVerContext}>
        <Router>
          <Routes>
            <Route path="/consent/:shortCode" element={<ConsentPage />} />
            <Route path="/DocType" element={<DocType />} />
            <Route path="/idUpload" element={<DocPicturePage />} />
            <Route path="/capturePhoto" element={<IdCardSelfiePicture />} />
            <Route path="/selfieUpload" element={<SelfiePicturePage />} />
            <Route path="/thanks" element={<ThankyouPage />} />
          </Routes>
        </Router>
      </DocVerificationContext.Provider>
    </div>
  );
}

export default App;
