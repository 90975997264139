/**
 * Doc Type Page
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */

//libary imports
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Backdrop,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

//components
import TuHeader from "../../components/TuHeader/TuHeader";

//styles
import {
  SelfiePicNextButton,
  SelfiePicturePageStyles,
} from "./SelfiePicturePage.styles";
import styles from "./SelfiePicturePage.module.css";

//component imports
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import FrontBackSelfieButton from "../../components/FrontBackSelfieButton/FrontBackSelfieButton";

//files
import SelfieGif from "../../images/selfie_instructions.gif";

//context
import { DocVerificationContext } from "../../context/DocVerificationContext";

const SelfiePicturePage = () => {
  const {
    selfieUploaded,
    selfieImageUrl,
    shortcode,
    updateSelfieImageUrl,
    updateSelfieUploaded,
    updateSelfieSubmitted,
  } = useContext(DocVerificationContext);
  const navigate = useNavigate();

  const [openBackdrop, setOpenBackdrop] = useState(false);

  const nextPageClicked = () => {
    if (selfieUploaded) {
      setOpenBackdrop(true);

      window.tudv.postSelfieToServer(
        shortcode,
        selfieImageUrl,
        (error, response) => {
          if (error) {
            console.error("Error submitting selfie to server", error);
            alert(error);
            setOpenBackdrop(false);
          } else {
            setOpenBackdrop(false);
            if (response.selfie && response.selfie == "rescan") {
              console.log("Rescan needed for selfie.", response);
              updateSelfieImageUrl("");
              updateSelfieUploaded();
            } else {
              updateSelfieSubmitted();
              navigate(`../thanks`, { replace: true });
            }
          }
        }
      );
    }
  };

  return (
    <Grid container spacing={2} sx={SelfiePicturePageStyles.pageWrapper}>
      <Backdrop open={openBackdrop} sx={{ color: "#fff", zIndex: "5999" }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <TuHeader />
      <Grid item xs={12} sx={SelfiePicturePageStyles.sectionWrapper}>
        <ProgressBar progressPhase={3} />
      </Grid>
      <Grid item xs={12} sx={SelfiePicturePageStyles.sectionWrapper}>
        <img className={styles.takeSelfie} src={SelfieGif} alt="gif" />
      </Grid>
      <Grid item xs={12} sx={SelfiePicturePageStyles.sectionWrapper}>
        <Stack direction="column" spacing={2}>
          <FrontBackSelfieButton
            key="selfiePic"
            imageType="selfie"
            imageCaptureLink={`../capturePhoto`}
          />

          {selfieUploaded ? (
            <SelfiePicNextButton onClick={nextPageClicked}>
              <Stack direction="row">
                <Typography
                  sx={{
                    textTransform: "capitalize",
                    fontSize: "1rem",
                    paddingTop: "0.25rem",
                  }}
                >
                  Submit Picture
                </Typography>
                <ArrowForwardIosIcon
                  sx={{
                    fontSize: "2rem",
                    color: "white",
                    paddingLeft: "0.5rem",
                  }}
                />
              </Stack>
            </SelfiePicNextButton>
          ) : (
            <></>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
};
export default SelfiePicturePage;
