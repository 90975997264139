/**
 * Doc Picture Page Styling
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";


export const DocPicPageStyles = {
  pageWrapper: {width:"100%", margin:"0px", padding:"10px", justifyContent:"center", textAlign:"center"},
  sectionWrapper: {display:"flex", justifyContent:"center",marginTop:"20px"},
}

export const DocPicNextButton = styled(Button)(({ theme }) => ({
  color: "white",
  width: "20rem",
  height: "5rem",
  backgroundColor: "#48cae4",
  borderRadius: "2rem",
  // boxShadow: "0 1px 3px 1px rgb(0 0 0 / 25%)",
  fontFamily: ["Roboto", "sans-serif"].join(","),
  textAlign: "center",
}));

export const DocPicPageItem = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  textAlign: "center",
  height: "2.5rem",
  color: "#013a63",
  fontSize: "1.75rem",
  fontFamily: "Oswald",
  marginLeft: "2rem",
  marginRight: "1.5rem",
  height: "100vh",
  //   marginTop: "2rem",
  boxShadow: "none",
}));

export const DocPicItemBlock = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  marginLeft: "2rem",
  marginRight: "2rem",
  display: "block",
  boxShadow: "none",
}));
