/**
 * Consent Loading Modal styles
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */
export const consentPageStyles = {
  loadingText: { color: "#023047", fontFamily: "roboto", fontWeight: "bold" },
};
