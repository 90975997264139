/**
 * Tu Header Logo style
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const TuHeaderItem = styled(Box)(({ theme }) => ({
  backgroundColor: "white",
  textAlign: "center",
  color: "black",
  width: "100%",
}));
