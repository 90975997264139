/**
 * Doc Type Page
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */

//libary imports
import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

//context
import { DocVerificationContext } from "../../context/DocVerificationContext";

const IdCardSelfiePicture = () => {
  const navigate = useNavigate();
  const {
    updateFrontUploaded,
    updateBackUploaded,
    imageToCapture,
    updateSelfieUploaded,
    updateFrontImageUrl,
    updateBackImageUrl,
    updateSelfieImageUrl,
    shortcode,
    setSelfieRequired,
  } = useContext(DocVerificationContext);

  const onError = (e) => {
    let n = e.toString();
    if (
      e.failureReasons &&
      e.failureReasons.length &&
      e.failureReasons.length > 0
    ) {
      n += ` - ${e.failureReasons.join()}`;
    }
    console.error(n);
    console.error(e);
  };

  const onFinished = (canceled, imageBase64) => {
    console.log("Finished capture", imageToCapture);
    console.log(imageBase64);

    if (!canceled) {
      switch (imageToCapture) {
        case "front":
          updateFrontImageUrl(imageBase64);
          updateFrontUploaded();
          navigate(`../idUpload`, { replace: true });
          break;
        case "back":
          updateBackImageUrl(imageBase64);
          updateBackUploaded();
          navigate(`../idUpload`, { replace: true });
          break;
        case "selfie":
          updateSelfieImageUrl(imageBase64);
          updateSelfieUploaded();
          navigate(`../selfieUpload`, { replace: true });
          break;
        default:
      }
    } else {
      navigate(`../idUpload`, { replace: true });
    }
  };

  useEffect(() => {
    /*
     * TruValidate js sdk should be configured and initialized at the top of the app
     * so that these calls will work
     */
    if (imageToCapture !== "selfie") {
      console.info(`Capturing document for side ${imageToCapture}`);
      let side =
        imageToCapture === "front"
          ? window.tudv.DocumentSide.FRONT
          : window.tudv.DocumentSide.BACK;
      window.tudv.prepareImageCapture((error) => {
        if (error) {
          console.error("error in prepare image capture = ", error);
          return;
        }
        setSelfieRequired(window.tudv.getIsSelfieRequired());
        window.tudv.captureDocument(
          window.tudv.DocumentType.LICENSE,
          side,
          onError,
          onFinished
        );
      });
    } else {
      window.tudv.prepareImageCapture((error) => {
        if (error) console.error("error in prepare image capture = ", error);
        window.tudv.captureSelfie(onError, onFinished);
      });
    }
  }, []);

  return <></>;
};
export default IdCardSelfiePicture;
