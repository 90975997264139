/**
 * Consent Loading Modal
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */

//libary imports
import React, { useContext } from "react";
import { Modal, Typography, Box } from "@mui/material";
//files
import PicGif from "../../images/mail.gif";
//styles
import { consentPageStyles } from "./ConsentLoading.styles";
import styles from "./ConsentLoading.module.css";

//context
import { DocVerificationContext } from "../../context/DocVerificationContext";

const ConsentLoading = () => {
  const { showConsentLoading, updateShowConsentLoading } = useContext(
    DocVerificationContext
  );

  const handleClose = () => {
    updateShowConsentLoading(false);
  };

  return (
    <Modal open={showConsentLoading} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 250,
          height: 300,
          bgcolor: "rgba(202, 240, 248, 0.85)",
          borderRadius: 1.5,
          boxShadow:
            "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
          padding: 4,
        }}
      >
        <Typography sx={consentPageStyles.loadingText}>
          Sending your consent over to Transunion
        </Typography>
        <Box
          sx={{
            backround: "blue",
            height: "10rem",
            marginTop: "2rem",
          }}
        >
          <img className={styles.mail} src={PicGif} alt="gif" />
        </Box>
      </Box>
    </Modal>
  );
};
export default ConsentLoading;
