/**
 * Doc Type Button Component
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */

//libary imports
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import AddCircleIcon from "@mui/icons-material/AddCircle";
//styles
import { FBSButton } from "./FrontBackSelfieButton.styles";
import styles from "./FrontBackSelfieButton.module.css";
//context
import { DocVerificationContext } from "../../context/DocVerificationContext";

const FrontBackSelfieButton = ({ imageType, imageCaptureLink }) => {
  //global state vars
  const {
    updateImageToCapture,
    frontUploaded,
    frontImageUrl,
    backUploaded,
    backImageUrl,
    selfieImageUrl,
    selfieUploaded,
  } = useContext(DocVerificationContext);
  //nav
  const navigate = useNavigate();
  //local state vars
  const [buttonText, setButtonText] = useState("Front of card");
  const [showPreview, setShowPreview] = useState(false);
  const [previewUrl, setPreviewUrl] = useState("");

  const clicked = () => {
    navigate(`${imageCaptureLink}`, { replace: true });
    switch (imageType) {
      case "back":
        updateImageToCapture("back");
        break;
      case "front":
        updateImageToCapture("front");
        break;
      case "selfie":
        updateImageToCapture("selfie");
    }
  };
  useEffect(() => {
    if (frontUploaded && imageType === "front") {
      setShowPreview(!showPreview);
      setPreviewUrl(frontImageUrl);
    } else if (!frontUploaded && imageType === "front") {
      setShowPreview(false);
      setPreviewUrl("");
    } else if (backUploaded && imageType === "back") {
      setShowPreview(!showPreview);
      setPreviewUrl(backImageUrl);
    } else if (!backUploaded && imageType === "back") {
      setShowPreview(false);
      setPreviewUrl("");
    } else if (selfieUploaded && imageType === "selfie") {
      setShowPreview(!showPreview);
      setPreviewUrl(selfieImageUrl);
    } else {
      // console.log("no preview");
    }
  }, [frontUploaded, backUploaded]);

  useEffect(() => {
    switch (imageType) {
      case "back":
        setButtonText("Back of Card");
        break;
      case "selfie":
        setButtonText("Selfie");
        break;
      default:
      // console.log("updating doc type to default");
    }
  }, [imageType]);

  return (
    <FBSButton onClick={clicked}>
      <Stack direction="row" spacing={2}>
        <Typography
          sx={{
            color: "black",
            fontWeight: 400,
            paddingTop: "0.25rem",
            fontSize: "1.2rem",
            fontFamily: "Roboto",
            textTransform: "capitalize",
            width: "10rem",
          }}
        >
          {buttonText}
        </Typography>
        {showPreview ? (
          <img src={previewUrl} className={styles.previewImage} />
        ) : (
          <AddCircleIcon
            sx={{
              color: "black",
              fontSize: "2rem",
              paddingLeft: "4.5rem",
            }}
          />
        )}
      </Stack>
    </FBSButton>
  );
};
export default FrontBackSelfieButton;
